.gm-style-iw {
display: flex !important;
flex-direction: row !important;
flex-direction: row-reverse !important;
justify-content: center !important;
align-items: center !important;
}


.gm-ui-hover-effect span {
		padding: 0px !important;
}